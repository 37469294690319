// 4px with default font
$base: 0.25rem;

$spaceS: $base * 4;
$spaceM: $base * 6;
$spaceL: $base * 8;
$spaceXL: $base * 16;

// Colors

// Colors - Neutrals
$neutral500: hsl(212, 60%, 75%);
$neutral300: hsl(212, 51%, 36%);
$neutral100: hsl(212, 61%, 16%);

//Colors - Support
$red900: hsl(360, 92%, 20%);
$red800: hsl(360, 85%, 25%);
$red700: hsl(360, 79%, 32%);
$red600: hsl(360, 72%, 38%);
$red500: hsl(360, 67%, 44%);
$red400: hsl(360, 64%, 55%);
$red300: hsl(360, 71%, 66%);
$red200: hsl(360, 77%, 78%);
$red100: hsl(360, 82%, 89%);

$orange900: hsl(6, 96%, 26%);
$orange800: hsl(8, 92%, 35%);
$orange700: hsl(10, 93%, 40%);
$orange600: hsl(12, 86%, 47%);
$orange500: hsl(14, 89%, 55%);
$orange400: hsl(16, 94%, 61%);
$orange300: hsl(18, 100%, 70%);
$orange200: hsl(20, 100%, 77%);
$orange100: hsl(22, 100%, 85%);

// fonts
$fontXXS: $base * 2.5;
$fontXS: $base * 3;
$fontS: $base * 3.5;
$fontM: $base * 4;
$fontL: $base * 4.5;
$fontXL: $base * 5;
$fontXXL: $base * 6;
$font3XL: $base * 7.5;
$font4XL: $base * 8.25;
$font5XL: $base * 11.25;
$font6XL: $base * 14.25;
$font7XL: $base * 17.25;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: black url(/static/media/bg5_darken_mobile.6c22ab2a.jpg) no-repeat center center fixed;
  height: 100%;
  background-size: cover;
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

* {
  margin: 0;
  padding: 0; }

ul,
li {
  margin: 0;
  padding: 0; }

body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-family: "Montserrat";
  font-weight: bold;
  min-height: 100%;
  -webkit-justify-content: center;
          justify-content: center; }

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%; }

.Container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 976px;
  padding: 1.5rem 1rem 1rem 1rem;
  color: white;
  font-size: 1rem; }
  .Container .main__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%; }
  .Container .introduction {
    display: block;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .Container .introduction > p {
      color: #99bde6;
      width: 100%;
      text-align: center;
      line-height: 1.25; }
  .Container .main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
    min-height: 250px; }
    .Container .main__capabilities {
      display: -webkit-flex;
      display: flex;
      -webkit-align-self: center;
              align-self: center; }
      .Container .main__capabilities__list {
        font-size: 1rem;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        font-weight: normal;
        list-style-type: none; }
        .Container .main__capabilities__list .react-icons {
          vertical-align: -3px;
          margin-right: 1rem;
          color: #99bde6;
          font-size: 1.25rem; }
    .Container .main__contact {
      display: -webkit-flex;
      display: flex;
      -webkit-align-self: center;
              align-self: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      font-size: 1.125rem; }
      .Container .main__contact__link {
        padding-left: 1rem; }
        .Container .main__contact__link > a,
        .Container .main__contact__link a:link,
        .Container .main__contact__link a:active,
        .Container .main__contact__link a:visited {
          display: -webkit-flex;
          display: flex;
          -webkit-align-self: center;
                  align-self: center;
          text-decoration: none;
          transition: color 0.2s ease-out; }
          .Container .main__contact__link > a:link,
          .Container .main__contact__link a:link:link,
          .Container .main__contact__link a:active:link,
          .Container .main__contact__link a:visited:link {
            color: #f25626; }
          .Container .main__contact__link > a:hover,
          .Container .main__contact__link a:link:hover,
          .Container .main__contact__link a:active:hover,
          .Container .main__contact__link a:visited:hover {
            color: #c52707; }
    .Container .main .footer {
      display: block;
      -webkit-justify-content: center;
              justify-content: center;
      color: #777;
      text-align: center;
      font-size: 0.625rem; }

@media only screen and (min-width: 768px) and (min-height: 432px) {
  html {
    background: black url(/static/media/bg5_darken.608c0a97.jpg) no-repeat center center fixed;
    height: 100%;
    background-size: cover;
    box-sizing: border-box; }
  .main__logo {
    width: 50%; }
  .Container {
    padding: 2rem;
    font-size: 1.5rem; }
    .Container .introduction {
      width: 70%;
      -webkit-align-self: center;
              align-self: center; }
      .Container .introduction > p {
        line-height: 1.5; }
    .Container .main {
      height: 100%;
      min-height: 300px; }
      .Container .main__capabilities__list {
        font-size: 1.5rem; }
        .Container .main__capabilities__list .react-icons {
          font-size: 1.5rem; }
      .Container .main__contact {
        -webkit-flex-direction: row;
                flex-direction: row;
        font-size: 2.0625rem;
        -webkit-justify-content: center;
                justify-content: center; }
        .Container .main__contact > a {
          margin-left: 1rem; }
      .Container .main .footer {
        font-size: 0.875rem; } }

.lds-ellipsis {
  display: -webkit-flex;
  display: flex;
  position: relative;
  top: 50%;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #99bde6;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }


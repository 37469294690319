@import "./variables";

html {
  background: black url("./assets/images/bg5_darken_mobile.jpg") no-repeat
    center center fixed;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex: 1;
  font-family: "Montserrat";
  font-weight: bold;
  min-height: 100%;
  justify-content: center;
}

.App {
  display: flex;
  flex: 1;
  height: 100%;
}

.Container {
  display: flex;
  flex-direction: column;
  max-width: 976px;
  padding: $spaceM $spaceS $spaceS $spaceS;
  color: white;
  font-size: $fontM;

  .main__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .introduction {
    display: block;
    width: 100%;
    margin-top: $spaceL;
    margin-bottom: $spaceL;

    > p {
      color: $neutral500;
      width: 100%;
      text-align: center;
      line-height: 1.25;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 250px;

    &__capabilities {
      display: flex;
      align-self: center;

      &__list {
        font-size: $fontM;
        display: flex;
        width: 100%;
        flex-direction: column;
        font-weight: normal;
        list-style-type: none;
        .react-icons {
          vertical-align: -3px;
          margin-right: 1rem;
          color: $neutral500;
          font-size: $fontXL;
        }
      }
    }

    &__contact {
      display: flex;
      align-self: center;
      align-items: center;
      flex-direction: column;
      font-size: $fontL;
      &__link {
        padding-left: $spaceS;
        > a,
        a:link,
        a:active,
        a:visited {
          display: flex;
          align-self: center;
          text-decoration: none;
          transition: color 0.2s ease-out;
          &:link {
            color: $orange500;
          }
          &:hover {
            color: $orange700;
          }
        }
      }
    }

    .footer {
      display: block;
      justify-content: center;
      color: #777;
      text-align: center;
      font-size: $fontXXS;
    }
  }
}

@media only screen and (min-width: 768px) and (min-height: 432px) {
  html {
    background: black url("./assets/images/bg5_darken.jpg") no-repeat center
      center fixed;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-sizing: border-box;
  }
  .main__logo {
    width: 50%;
  }
  .Container {
    padding: 2rem;
    font-size: $fontXXL;
    .introduction {
      width: 70%;
      align-self: center;
      > p {
        line-height: 1.5;
      }
    }
    .main {
      height: 100%;
      min-height: 300px;

      &__capabilities__list {
        font-size: $fontXXL;
        .react-icons {
          font-size: $fontXXL;
        }
      }

      &__contact {
        flex-direction: row;
        font-size: $font4XL;
        justify-content: center;
        > a {
          margin-left: 1rem;
        }
      }
      .footer {
        font-size: $fontS;
      }
    }
  }
}
